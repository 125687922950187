import React from "react";
import Detail from "./Detail";
import Form from "./Form";

const Details = [
  {
    title: "Egypt's Ministry of Health Breast Cancer Diploma",
    desc: "5 Breast cancer certified Modules to enable the Egyptian Oncologists to acquire an advanced level of knowledge in the field of breast diseases. This will allow candidates to be today’s breast cancer experts and future leaders to pass on the legacy of cutting edge and evidence-based medicine.",
  },
  {
    title: "5 Disciplines of Breast Cancer",
    desc: "The project will address all 5 disciplines involved in the management of breast cancer: Breast Cancer Surgery, Pathology, Breast Imaging, Medical Oncology, and Radiotherapy.",
  },
  {
    title: "Affiliated With One Of The Best Medicine Schools in the U.S",
    desc: "The program will be made available through partnership with the Northwestern Memorial Hospital esteemed faculty - being one of the world’s leading cancer centers – Ranked 8th as per US News 2021 cancer hospitals ranking.",
  },
];

const Registering = () => {
  return (
    <article className="my-8 md:flex md:justify-evenly md:items-start md:gap-8">
      <section className="my-8 md:w-[50%]">
        {Details.map((detail) => (
          <Detail key={detail.title} title={detail.title} desc={detail.desc} />
        ))}
      </section>
      <section className="my-8 md:w-[50%]">
        <Form />
      </section>
    </article>
  );
};

export default Registering;
