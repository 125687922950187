import React from "react";

const Detail = ({ title, desc }) => {
  return (
    <div className="my-8">
      <h3 className="text-[#f67e93] text-2xl font-bold my-2">{title}</h3>
      <p className="text-[#6C757D] font-semibold my-2">{desc}</p>
    </div>
  );
};

export default Detail;
