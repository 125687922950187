import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";
import { ErrorBoundary } from "react-error-boundary";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ErrorBoundary
        fallback={
          <div className="flex justify-center h-[100vh] items-center">
            <h1 className="text-3xl">Something Went Wrong</h1>
          </div>
        }
      >
        <ChakraProvider>
          <App />
        </ChakraProvider>
      </ErrorBoundary>
    </BrowserRouter>
  </React.StrictMode>
);
