import Home from "./pages/Home";
import { Routes, Route, Navigate } from "react-router-dom";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import ThankYou from "./pages/ThankYou";
import { useNavigate, useSearchParams } from "react-router-dom";
import SubmitVideo from "./pages/SubmitVideo";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/thank-you" element={<ThankYou />} />
      <Route path="/submit-video" element={<SubmitVideo />} />
    </Routes>
  );
}

export default App;
