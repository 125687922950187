/**
 * This page handles the video submission for the users which are already
 * registered but didn't submit a video, they recieve an email that has a link
 * to this route
 */

import React, { useState, useRef } from "react";
import Video from "../components/Video";
import { Spinner } from "@chakra-ui/react";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";
import { useNavigate, useSearchParams } from "react-router-dom";

const SubmitVideo = () => {
  /**
   * The search params in this page has both a token to handle
   * authorization and a name to handle the video name and to
   * show the users name as a header
   */
  const [searchParams, setSearchParams] = useSearchParams();

  const [formFeedback, setFormFeedback] = useState("");

  const navigate = useNavigate();

  /**
   * The video state, and a reference we use to reset the
   * file input when the cancel button is clicked
   */
  const [selectedFile, setSelectedFile] = useState(null);
  const fileRef = useRef();

  /**
   * Loading state to to display a loading spinner
   * when the video is being uploaded and the form is
   * submitted, also the states for the sizes to calculate
   * the progress percentage
   */
  const [loading, setLoading] = useState(false);
  const [maxSize, setMaxSize] = useState(0);
  const [currentSize, setCurrentSize] = useState(0);

  /**
   * Google recaptcha token state
   */
  const [recaptchaResponseToken, setRecaptchaResponseToken] = useState("");
  const recaptchaRef = useRef();

  const onSubmitHandler = async (e) => {
    e.preventDefault();

    setLoading(true);

    const formData = new FormData();

    // Modifing the video file to change its name
    // to the name of the user rather than the
    // name of the default video
    const modifiedFile = new File(
      [selectedFile],
      searchParams.get("name").split(" ").join("").toLowerCase(),
      {
        type: selectedFile.type,
      }
    );

    formData.append("video", modifiedFile);
    formData.append("recaptchaResponse", recaptchaResponseToken);

    setMaxSize(selectedFile.size);

    /**
     * request to the backend to post the user form data to the api
     * onUploadProgress helps us to know how many of the form have been submitted,
     * so we can handle the loading indicator
     */
    let res;
    try {
      res = await axios.patch(
        `${
          process.env.REACT_APP_API_DOMAIN
        }/users/update?token=${searchParams.get("token")}`, // The route has a token so we can verify it in the backend
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (progressEvent) =>
            setCurrentSize(progressEvent.loaded),
        }
      );

      navigate("/thank-you");
      setLoading(false);
    } catch (error) {
      setFormFeedback(error.response.data.message);
      setLoading(false);
      recaptchaRef.current.props.grecaptcha.reset();
    }
  };

  return (
    <main className="px-6 flex justify-center">
      {/* if token exists, show the form, if not, display a non authorized header */}
      {searchParams.get("token") && (
        <article className="my-8 text-center">
          <h1 className="text-4xl">Welcome, {searchParams.get("name")}</h1>
          <p>Kindley, upload a video.</p>
          <form className="my-8" onSubmit={onSubmitHandler}>
            <Video
              selectedFile={selectedFile}
              setSelectedFile={setSelectedFile}
              fileRef={fileRef}
              loading={loading}
              currentSize={currentSize}
              maxSize={maxSize}
            />
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
              className="my-4"
              onChange={(token) => {
                setRecaptchaResponseToken(token);
              }}
              ref={recaptchaRef}
            />
            <div className="flex flex-row mb-2 text-red-500 justify-center">
              <p className="text-lg">{formFeedback}</p>
            </div>
            {/**
             * the submit button is disabled if there is no file, and when pressed,
             * it disappears and a loading spinner is shown
             */}
            <div className="flex flex-col mb-2 items-center">
              {loading && <Spinner size="xl" color="red.500" />}
              {!loading && (
                <button
                  type="submit"
                  className={`w-full px-6 py-4 text-2xl text-center font-bold rounded-[32px] ${
                    !selectedFile
                      ? "bg-[#6C757D] text-slate-400"
                      : "bg-white text-[#f67e93]"
                  }`}
                  disabled={!selectedFile}
                >
                  Submit
                </button>
              )}
            </div>
          </form>
        </article>
      )}
      {!searchParams.get("token") && (
        <h1 className="text-4xl py-8">Unauthorized.</h1>
      )}
    </main>
  );
};

export default SubmitVideo;
