/**
 * This is the Form component which handles the form
 */

import React, { useLayoutEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Input from "./Input";
import Select from "./Select";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Spinner } from "@chakra-ui/react";
import CheckBox from "./CheckBox";
import Video from "./Video";
import ReCAPTCHA from "react-google-recaptcha";

/**
 * Yup schema
 */
const schema = yup.object().shape({
  englishName: yup.string().required(),
  arabicName: yup.string().required(),
  email: yup.string().email().required(),
  phone: yup.string().required(),
  region: yup.string().required(),
  speciality: yup.string().required(),
  qualification: yup.string().required(),
  trainingYears: yup.string().required(),
  employer: yup.string().required(),
  checkBoxValue: yup.boolean(),
});

/**
 * This array makes it easier to handle the form fields,
 * we use it to dynamically add or remove a field
 */
const InputsArr = [
  {
    id: "englishName",
    label: "Full English Name",
    type: "text",
  },
  {
    id: "arabicName",
    label: "الاسم كامل بالعربي",
    type: "text",
  },
  {
    id: "email",
    label: "Your Email",
    type: "email",
  },
  {
    id: "phone",
    label: "Phone Number",
    type: "text",
  },
  {
    id: "employer",
    label: "Employer",
    type: "text",
  },
];

const Form = () => {
  // formFeedback which displays the api feedback message when an error happens
  const [formFeedback, setFormFeedback] = useState("");

  /**
   * States for the data we fetch from the api,
   * this data is used in the select fields
   */
  const [qualifications, setQualifications] = useState([]);
  const [regions, setRegions] = useState([]);
  const [specialities, setSpecialities] = useState([]);
  const [trainingYears, setTrainingYears] = useState([]);

  /**
   * Google recaptcha token state
   */
  const [recaptchaResponseToken, setRecaptchaResponseToken] = useState("");
  const recaptchaRef = useRef();

  /**
   * The video state, and a reference we use to reset the
   * file input when the cancel button is clicked
   */
  const [selectedFile, setSelectedFile] = useState(null);
  const fileRef = useRef();

  /**
   * Loading state to to display a loading spinner
   * when the video is being uploaded and the form is
   * submitted, also the states for the sizes to calculate
   * the progress percentage
   */
  const [loading, setLoading] = useState(false);
  const [maxSize, setMaxSize] = useState(0);
  const [currentSize, setCurrentSize] = useState(0);

  /**
   * This hooks is required to fetch the select fields
   * data from the api before the first render of the
   * app
   */
  useLayoutEffect(() => {
    const getData = async (route, stateSetter) => {
      const response = await fetch(
        `${process.env.REACT_APP_API_DOMAIN}/data/${route}`
      );

      const data = await response.json();

      stateSetter(data[`${route}`]);
    };

    getData("qualifications", setQualifications);
    getData("regions", setRegions);
    getData("specialities", setSpecialities);
    getData("trainingYears", setTrainingYears);
  }, []);

  /**
   * The select array which make generating the select
   * fields and options dynamic
   */
  const SelectArray = [
    {
      id: "region",
      label: "Region",
      data: regions,
    },
    {
      id: "speciality",
      label: "Speciality",
      data: specialities,
    },
    {
      id: "qualification",
      label: "Qualification",
      data: qualifications,
    },
    {
      id: "trainingYears",
      label: "Training Years",
      data: trainingYears,
    },
  ];

  /**
   * The useForm hook which handles the form states
   */
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const navigate = useNavigate();

  /**
   * The submission function
   */
  const onSubmitHandler = async (data) => {
    setLoading(true);

    const formData = new FormData();

    // Modifing the video file to change its name
    // to the name of the user rather than the
    // name of the default video
    const modifiedFile = new File(
      [selectedFile],
      data.englishName.split(" ").join("").toLowerCase(),
      {
        type: selectedFile.type,
      }
    );

    formData.append("video", modifiedFile);
    formData.append("recaptchaResponse", recaptchaResponseToken);

    // dynamically appending the form data to the
    // formData object
    Object.keys(data).forEach((e) => {
      formData.append(e, data[`${e}`]);
    });

    setMaxSize(selectedFile.size);

    /**
     * request to the backend to post the user form data to the api
     * onUploadProgress helps us to know how many of the form have been submitted,
     * so we can handle the loading indicator
     */
    let res;
    try {
      res = await axios.post(
        `${process.env.REACT_APP_API_DOMAIN}/users/register`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (progressEvent) =>
            setCurrentSize(progressEvent.loaded),
        }
      );

      navigate("/thank-you");
      reset();
      setLoading(false);
    } catch (error) {
      setFormFeedback(error.response.data.message);
      setLoading(false);
      recaptchaRef.current.props.grecaptcha.reset();
    }
  };

  return (
    <div className="bg-[#cccccc] text-white p-8 rounded-[32px] rounded-tr-none rounded-br-none">
      <h2 className="bg-white text-[#f67e93] px-6 py-4 text-2xl text-center font-bold rounded-[32px]">
        Register Now
      </h2>
      <form className="my-8" onSubmit={handleSubmit(onSubmitHandler)}>
        {/* Mapping the inputs array */}
        {InputsArr.map((input) => (
          <Input
            key={input.id}
            id={input.id}
            label={input.label}
            type={input.type}
            register={register}
            errors={errors}
          />
        ))}
        {/* Mapping the select array */}
        {SelectArray.map((select) => (
          <Select
            key={select.id}
            id={select.id}
            label={select.label}
            data={select.data}
            register={register}
            errors={errors}
          />
        ))}
        <CheckBox register={register} errors={errors} />
        <div className="flex flex-row mb-2 text-black">
          <p>
            By Clicking Submit Now, you agree to{" "}
            <Link to="/privacy-policy" className="text-emerald-600">
              privacy policy
            </Link>
          </p>
        </div>
        <Video
          setSelectedFile={setSelectedFile}
          selectedFile={selectedFile}
          fileRef={fileRef}
          loading={loading}
          currentSize={currentSize}
          maxSize={maxSize}
        />
        <ReCAPTCHA
          sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
          className="my-4"
          onChange={(token) => {
            setRecaptchaResponseToken(token);
          }}
          ref={recaptchaRef}
        />
        <div className="flex flex-row mb-2 text-red-500 justify-center">
          <p className="text-lg">{formFeedback}</p>
        </div>
        {/**
         * the submit button is disabled if there is no file, and when pressed,
         * it disappears and a loading spinner is shown
         */}
        <div className="flex flex-col mb-2 items-center">
          {loading && <Spinner size="xl" color="red.500" />}
          {!loading && (
            <button
              type="submit"
              className={`w-full px-6 py-4 text-2xl text-center font-bold rounded-[32px] ${
                !selectedFile
                  ? "bg-[#6C757D] text-slate-400"
                  : "bg-white text-[#f67e93]"
              }`}
              disabled={!selectedFile}
            >
              Submit
            </button>
          )}
        </div>
      </form>
    </div>
  );
};

export default Form;
